import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

function NextUp({ locationOne, locationTwo }) {
  return (
    <div className="bg-secondary text-center text-tertiary text-xl py-8 lg:py-20">
      <div className="container-fluid flex justify-center items-stretch relative">
        <div className="w-full sm:px-4">
          <h3 className="text-4xl xs:text-5xl leading-tight md:text-6xl lg:text-8xl mb-2 sm:mb-0 font-display mb-4 sm:mb-12">
            Where next?
          </h3>
          <div className="flex flex-wrap justify-around relative z-0">
            {locationOne && (
              <AniLink
                cover
                to={locationOne.url}
                entry={{ delay: 0.5 }}
                duration={1}
                bg="#4D6D69"
                className="btn w-full xs:w-auto mb-4 sm:mb-0"
              >
                {locationOne.label}
              </AniLink>
            )}

            {locationTwo && (
              <AniLink
                cover
                to={locationTwo.url}
                entry={{ delay: 0.5 }}
                duration={1}
                bg="#4D6D69"
                className="btn w-full xs:w-auto mb-4 sm:mb-0"
              >
                {locationTwo.label}
              </AniLink>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default NextUp
