import React from "react"
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import WelcomeMessage from "../components/WelcomeMessage"
import NextUp from "../components/NextUp"

class IndexPage extends React.Component {
  render () {
    return(
      <Layout>
        <SEO title="👰🤵 Welcome" />
        <WelcomeMessage />
        <NextUp
        locationOne={
          {
            label: 'Our Day',
            url: '/ceremony'
          }
        }
        locationTwo={
          {
            label: 'The Location',
            url: '/location'
          }
        }
        />

      </Layout>
    )
  }
}
export default IndexPage
