import React from "react"
import anime from "animejs/lib/anime.es.js"

class WelcomeMessage extends React.Component {
  componentWillUpdate() {
    let { el } = this

    let textWrappers = el.children,
      i
    for (i = 0; i < textWrappers.length; ++i) {
      textWrappers[i].innerHTML = textWrappers[i].textContent.replace(
        /[-A-Za-z0-9!$#%^&–*@()_+|~=`{}:";'<>?,.]/g,
        "<span className='letter inline-block leading-none'>$&</span>"
      )
    }

    anime
      .timeline({
        loop: false,
      })
      .add({
        targets: ".moving-letters > *",
        translateX: [40, 0],
        translateZ: 0,
        opacity: [0, 1],
        easing: "linear",
        duration: 2000,
        delay: function (el, i) {
          return 1000 + 40 * i
        },
      })
  }
  render() {
    return (
      <div
        className="
        welcome-message
        min-h-screen
        flex
        flex-col
        justify-center
        bg-primary
        font-display
        text-secondary
        text-4xl
        xs:text-5xl
        leading-1
        md:text-4xl
        lg:text-6xl
        2xl:text-9xl
        text-center
        break-normal
        pt-2
        pb-8
        px-2
        md:px-4
      "
        ref={el => {
          this.el = el
        }}
      >
        <p className="moving-letters m-0">Welcome,</p>
        <p className="moving-letters m-0">you have been cordially</p>
        <p className="moving-letters m-0">invited to the</p>
        <p className="moving-letters m-0">wedding of</p>
        <p className="moving-letters m-0">Holly Elliott</p>
        <p className="moving-letters m-0">and Oliver Towle</p>
        <p className="moving-letters m-0">on 20th July 2024.</p>
        <p className="moving-letters m-0">Lets party!</p>
      </div>
    )
  }
}
export default WelcomeMessage
